<template>
	<div class="upload">
		<div class="preview">
			<img v-if="image" :src="image" alt="Preview" />
			<div class="dropzone" @dragover.prevent @drop="handleDrop">
				<span v-if="!image" style="color: red">拖放文件到此处或点击上传</span>
			</div>
			<input type="file" ref="fileInput" @change="handleFileInput" />
		</div>
	</div>
</template>

<script>
  import {
    GetPreignUrl
  } from '../httpcli/upload.js'
  import axios from 'axios'

	export default {
		data() {
			return {
				image: null,
			}
		},
		methods: {
			handleFileInput(event) {
				const file = event.target.files[0]
				this.previewImage(file)
			},
			handleDrop(event) {
				event.preventDefault()
				const file = event.dataTransfer.files[0]
				this.previewImage(file)
			},
			async previewImage(file) {
        console.log('----------- file:', file)
        if (file != undefined) {
          this.$emit('startEvent', true)
        } else {
          return
        }

        let fileName = file.name;
        let fileSize = file.size;

        console.log('-------- fileName:', fileName)
        console.log('-------- fileSize:', fileSize)

        const newName = new Date().getTime() + fileName

        const { data } = await GetPreignUrl({
          name: newName,
          size: fileSize,
          preset_id: "998877665544332211",
        })

        console.log('---------- data:', data)

        if (data && data[0]) {
          const {
            access_url,
            header,
            url
          } = data[0]

          console.log('access_url', access_url)
          console.log('url', url)

          var vm = this;

          var reader = new FileReader() //生成FileReader实例
          reader.readAsDataURL(file)
          reader.onload = () => {
            // 这里用于预览图片
            this.image = reader.result

            // 转化为二进制
            let binaryImg = atob(this.image.split(',')[1]);
            let len = binaryImg.length;
            let bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = binaryImg.charCodeAt(i);
            }

            // 将 Uint8Array 类型的数据转换为 ArrayBuffer 类型的数据
            let arrayBuffer = bytes.buffer;

            axios({
              method: 'put',
              url,
              data: arrayBuffer,
              headers: header,
              timeout: 60000,
            })
            .then(response => {
              console.log('上传成功 accessUrl:', access_url)
              console.log('上传成功 response:', response)
              vm.$emit('accessEvent', {
                "access_url": access_url,
                "status": true
              })
            })
            .catch(() => {
              console.log('上传失败')
              vm.$emit('accessEvent', {
                "access_url": access_url,
                "status": false
              })
            })
          }
        }
			},
		},
	}
</script>

<style>
	.upload {
		display: inline-flex;
		justify-content: center;
	}

	.preview {
		position: relative;
		width: 300px;
		height: 300px;
		border: 1px solid #ddd;
		margin: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.preview img {
		max-width: 100%;
		max-height: 100%;
		object-fit: contain;
	}

	.preview input[type='file'] {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
	}

	.dropzone {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 80%;
		display: flex;
		justify-content: center;
		align-items: center;
		/*background-color: rgba(255, 255, 255, 0.7);*/
	}

	.dropzone span {
		font-size: 16px;
		color: #666;
	}
</style>
