<template>
	<div>
		<Layout>
<!--			<Header :style="{background: '#333333'}">-->
<!--				<Menu mode="horizontal" :style="{background: '#333333'}" active-name="1">-->
<!--					<div class="layout-logo">-->
<!--						<svg t="1649066298395" class="icon" viewBox="0 0 1024 1024" version="1.1"-->
<!--							xmlns="http://www.w3.org/2000/svg" p-id="24652" width="64" height="64">-->
<!--							<path-->
<!--								d="M50.673152 590.181581l71.227187-33.565491 0 138.349975-71.227187 18.831872L50.673152 590.181617 50.673152 590.181581zM142.367334 734.264115l75.315405-17.19255 0 151.449317-75.315427 0L142.367312 734.264153 142.367334 734.264115zM142.367334 322.482688l75.315405-60.578714L217.682739 459.198423l-75.315405 36.839014L142.367334 322.482669 142.367334 322.482688zM142.367334 546.7904l75.315405-34.379981 0 157.99643-75.315405 19.646464L142.367334 546.790364 142.367334 546.7904zM248.795648 238.164275l103.149466-82.684211 0 239.860926-103.149466 49.93833L248.795648 238.164285 248.795648 238.164275zM50.673152 754.730291l71.227187-15.558349L121.900339 868.520903 50.67318 868.520903 50.67318 754.730277 50.673152 754.730291zM248.795648 710.519398l103.149466-22.919987L351.945114 868.520903 248.795622 868.520903 248.795622 710.519356 248.795648 710.519398zM50.673152 395.341005l71.227187-56.485478 0 166.187997-71.227187 34.379981L50.673152 395.341047 50.673152 395.341005zM248.795648 498.49129l103.149466-48.299008 0 185.834452-103.149466 26.193613L248.795648 498.491335 248.795648 498.49129zM973.326848 238.164275l-27.019469 630.356582-16.373586 0-17.193267-590.244045-311.912755-49.93833-2.459136 179.282227 140.814848 18.831872 2.454016-176.008704 44.207616 7.366758-1.634304 174.36928 134.262374 18.012262 0 53.211922-135.082086-16.372941-1.639424 166.182912 136.72151 9.00608 0 36.019355-137.536102-6.547149-1.639424 176.828314-45.027363 0 2.454016-178.467635-142.449152-6.547149-2.454016 171.095757-44.20766 0L547.613484 681.866782l-169.468621-8.18647 0-49.118698 169.468621 12.279706L547.613484 460.018092l-169.468621-18.831872 0-62.21804 169.468621 22.105498L547.613484 220.152049l-162.921267-26.193613-6.547354-33.565491L973.326848 238.164275zM596.734362 465.74551l-1.639424 173.55479 141.62944 9.820672 2.459136-167.002522L596.734362 465.74551zM596.734362 465.74551"-->
<!--								p-id="24653"></path>-->
<!--						</svg>-->
<!--					</div>-->
<!--				</Menu>-->
<!--			</Header>-->
			<Layout>
				<Sider hide-trigger :style="{minHeight: '100vh', background: '#333333'}">
					<Menu :active-name="atviveName" width="auto" :style="{minHeight: '100vh', background: '#333333'}"
						:open-names="['1']" @on-select="turnUrl">
						<Submenu name="1">
							<template #title>
								<Icon type="md-images" size=24 color="white"></Icon>
								<span style="font-size: 16px;color: white;font-weight:500;">创作</span>
							</template>
							<MenuItem name="1-1">
							<span style="font-size: 14px;color: white;">文生图</span>
							</MenuItem>
							<MenuItem name="1-2">
							<span style="font-size: 14px;color: white;">图生图</span>
							</MenuItem>
              <MenuItem name="1-3">
                <span style="font-size: 14px;color: white;">条件生图</span>
              </MenuItem>
              <MenuItem name="1-4">
                <span style="font-size: 14px;color: white;">图片处理</span>
              </MenuItem>
						</Submenu>
<!--						<Submenu name="2">-->
<!--							<template #title>-->
<!--								<Icon type="md-time" size=24 color="white"></Icon>-->
<!--								<span style="font-size: 16px;color: white;font-weight:500;">训练</span>-->
<!--							</template>-->
<!--							<MenuItem name="2-1">-->
<!--							<span style="font-size: 14px;color: white;">风格定制</span>-->
<!--							</MenuItem>-->
<!--						</Submenu>-->
<!--						<Submenu name="3">-->
<!--							<template #title>-->
<!--								<Icon type="md-aperture" size=24 color="white"></Icon>-->
<!--								<span style="font-size: 16px;color: white;font-weight:500;">模版</span>-->
<!--							</template>-->
<!--							<MenuItem name="3-1">-->
<!--							<span style="font-size: 14px;color: white;">风格定制</span>-->
<!--							</MenuItem>-->
<!--							<MenuItem name="3-2">-->
<!--							<span style="font-size: 14px;color: white;">风格定制</span>-->
<!--							</MenuItem>-->
<!--						</Submenu>-->
					</Menu>
				</Sider>
				<Layout>
					<Content :style="{background: '#333333'}">
						<div v-if="textToimage">
							<TextToImage :accountid="accountId"/>
						</div>
						<div v-if="imageToimage">
							<ImageToImage :accountid="accountId" />
						</div>
            <div v-if="controlnetToimage">
              <ControlnetToImage :accountid="accountId" />
            </div>
            <div v-if="imagePhotoshop">
              <ImagePhotoshop :accountid="accountId" />
            </div>
<!--						<div v-if="train">-->
<!--							<Train />-->
<!--						</div>-->
<!--						<div v-if="templateA" :images="images">-->
<!--							<TemplateA />-->
<!--						</div>-->
					</Content>
				</Layout>
			</Layout>
		</Layout>
	</div>
</template>

<script>
	import TextToImage from './creation/text_to_image.vue'
	import ImageToImage from './creation/image_to_image.vue'
  import ControlnetToImage from './creation/controlnet_to_image.vue'
  import ImagePhotoshop from './creation/image_photoshop.vue'
	import Train from './train/train.vue'
	import TemplateA from './template/template_a.vue'

	export default {
		components: {
			TextToImage,
			ImageToImage,
      ControlnetToImage,
      ImagePhotoshop,
			Train,
			TemplateA,
		},
		data() {
			return {
				isCollapsed: false,
				textToimage: true,
				imageToimage: false,
        controlnetToimage: false,
        imagePhotoshop: false,
				train: false,
				templateA: false,
				templateB: false,
				loading: false,
        atviveName: '1-1',
        accountId: '',

				images: [{
						alt: '浩瀚星空',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '现实写真',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '动漫',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '性感',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '现实写真',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '动漫',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '性感',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '现实写真',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '动漫',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '性感',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '动漫',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '性感',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
				],
			}
		},
    created() {
      this.accountId = this.$route.query.accountid;
      console.log('------ this.$route.query.accountid:', this.$route.query.accountid);
      console.log('------ this.accountid:', this.accountId);
    },
		mounted() {

		},
		methods: {
			turnUrl(name) {
				switch (name) {
					case '1-1':
						this.textToimage = true;
						this.imageToimage = false;
            this.imagePhotoshop = false;
						this.train = false;
            this.controlnetToimage = false;
						this.templateA = false;
						break;
					case '1-2':
						this.imageToimage = true;
						this.textToimage = false;
            this.imagePhotoshop = false;
						this.train = false;
            this.controlnetToimage = false;
            this.templateA = false;
						break;
          case '1-3':
            this.imageToimage = false;
            this.textToimage = false;
            this.imagePhotoshop = false;
            this.train = false;
            this.controlnetToimage = true;
            this.templateA = false;
            break;
          case '1-4':
            this.imagePhotoshop = true;
            this.imageToimage = false;
            this.textToimage = false;
            this.train = false;
            this.controlnetToimage = false;
            this.templateA = false;
            break;
					case '2-1':
						this.textToimage = false
						this.imageToimage = false
						this.train = true
            this.controlnetToimage = false
						this.templateA = false
						break;
					case '3-1':
						this.imageToimage = false
						this.textToimage = false
						this.train = false
            this.controlnetToimage = false
						this.templateA = true
						break;
					case '3-2':
						this.imageToimage = false
						this.textToimage = false
						this.train = false
            this.controlnetToimage = false
						this.templateA = false
						break;
					default:
						break;
				}
				console.log('---------- 选择中的name:', name)
			},
		}
	}
</script>

<style scoped>
	.layout {
		background: #333333;
		position: relative;
		height: 100vh;
	}

	/*.rotate-icon{*/
	/*  transform: rotate(-90deg);*/
	/*}*/
	.menu-item span {
		display: inline-block;
		overflow: hidden;
		width: 69px;
		text-overflow: ellipsis;
		white-space: nowrap;
		vertical-align: bottom;
		transition: width .2s ease .2s;
	}

	.menu-item i {
		transform: translateX(0px);
		transition: font-size .2s ease, transform .2s ease;
		vertical-align: middle;
		font-size: 16px;
	}

	.collapsed-menu span {
		width: 0px;
		transition: width .2s ease;
	}

	.collapsed-menu i {
		transform: translateX(5px);
		transition: font-size .2s ease .2s, transform .2s ease .2s;
		vertical-align: middle;
		font-size: 22px;
	}

	.ivu-modal-header-inner {
		color: dimgray;
		font-size: 18px;
	}

	.highlighted {
		text-align: center;
		background: yellow;
	}

	.ordinary {
		text-align: center;
	}

	.layout-logo {
		width: 100px;
		height: 64px;
		border-radius: 3px;
		float: left;
		position: relative;
		top: 2px;
		bottom: 2px;
	}

	.layout-nav {
		width: 420px;
		margin: 0 auto;
		margin-right: 20px;
		height: 100vh;
	}

	.ivu-layout-header {
		padding: 0;
	}

	/* 	.ivu-menu-vertical.ivu-menu-light:after {
		background: #333333;
	} */
	.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) {
		background: #808080;
	}

	.ivu-layout-header {
		height: 60px;
	}
</style>
