import service from "./service"

/**
 * @description: 获取上传地址
 */
export async function GetPreignUrl({
	name,
	size,
	preset_id
}) {

	return service({
		url: "/api/v1/cos/presign",
		method: 'post',
		data: [{
			name,
			size,
			preset_id
		}]
	})
}
