<template>
	<div id="global">

	</div>
</template>

<script>
	import Vue from 'vue'

	export default {
		name: "train",
		data() {
			return {

			}
		},
		mounted() {

		},
		methods: {}
	}
</script>

<style scoped>
	.red {
		color: red;
	}

	.blue {
		color: blue;
	}
</style>
