<!-- Loading.vue -->
<template>
	<div class="loading">
		<Row>
			<div class="loading-animation"></div>
		</Row>
		<br />
		<Row>
			<div class="loading-text">{{ title }}</div>
		</Row>

	</div>
</template>

<script>
	export default {
		name: 'Loading',
    props: {
      title: String,
    },
		data() {
			return {
				text: '正在上传中, 请稍等...'
			}
		}
	}
</script>

<style scoped>
	.loading {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 9999;
	}

	.loading-animation {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		border: 6px solid #ccc;
		border-top-color: #3f51b5;
		animation: rotate 1s linear infinite;
		margin-bottom: 20px;
		margin-right: 20px;
	}

	.loading-text {
		font-size: 16px;
		margin-right: 60px;
		color: white;
	}

	@keyframes rotate {
		to {
			transform: rotate(360deg);
		}
	}
</style>
