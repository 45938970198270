<!-- <template>
	<div>
		<Row v-for="(row, index) in 3" :key="index">
			<Col span='6' v-for="col in 4" :key="col">
			<Card :style="{background: '#4c4c4c', height:'300px'}" no-header>

				<Img :src="accessImageUrl" height="300px" width="300px" style="margin: 10px auto" />
			</Card>

			</Col>
		</Row>

	</div>

</template>

<script>
	import Vue from 'vue'

	export default {
		name: "template-A",
		data() {
			return {
				rows: 3, // 初始行数为 3
				accessImageUrl: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg',
				privewList: [{
						value: '浩瀚星空',
						label: '浩瀚星空'
					},
					{
						value: '现实写真',
						label: '现实写真'
					},
					{
						value: '动漫',
						label: '动漫'
					},
					{
						value: '性感',
						label: '性感'
					},
					{
						value: '现实写真',
						label: '现实写真'
					},
					{
						value: '动漫',
						label: '动漫'
					},
					{
						value: '性感',
						label: '性感'
					},
					{
						value: '现实写真',
						label: '现实写真'
					},
					{
						value: '动漫',
						label: '动漫'
					},
					{
						value: '性感',
						label: '性感'
					},
					{
						value: '动漫',
						label: '动漫'
					},
					{
						value: '性感',
						label: '性感'
					},
				],
			}
		},
		mounted() {

		},
		methods: {}
	}
</script>

<style scoped>

</style>
 -->

<template>
	<div class="image-wall">
		<div v-for="(row, index) in rows" :key="index" class="image-row">
			<div v-for="(image, index) in row" :key="index" class="image-col">
				<Card :style="{background: '#4c4c4c', height: '300px', margin: '0 0'}" no-header>
					<img :src="image.url" :alt="image.alt" height='270px' width='auto' />
				</Card>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "ImageWall",
		// props: {
		// 	images: {
		// 		type: Array,
		// 		required: true,
		// 	},
		// },
		data() {
			return {
				images: [{
						alt: '浩瀚星空',
						url: 'https://img.aiartface.com/998877665544332211/fc0d639c36c0a6de33c894e418c83fb9.jpg'
					},
					{
						alt: '现实写真',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '动漫',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '性感',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '现实写真',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '动漫',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '性感',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '现实写真',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '动漫',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '性感',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '动漫',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
					{
						alt: '性感',
						url: 'https://img.aiartface.com/998877665544332211/62039f3cb9b41b37ccbaa4df89ddc8d2.jpg'
					},
				],
			}
		},
		computed: {
			rows() {
				const rows = [];
				const imagesPerRow = 4;
				const numOfRows = Math.ceil(this.images.length / imagesPerRow);
				for (let i = 0; i < numOfRows; i++) {
					rows.push(this.images.slice(i * imagesPerRow, (i + 1) * imagesPerRow));
				}
				return rows;
			},
		},
	};
</script>

<style>
	.image-wall {
		display: flex;
		flex-wrap: wrap;
	}

	.image-row {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.image-col {
		width: 24%;
		/* width: 100%; */
	}

	.box img {
		width: 100%;
		height: 270px;
	}

	.box1 img {
		object-fit: cover;
	}

	.box2 img {
		object-fit: contain;
	}

	.box3 img {
		object-fit: fill;
	}
</style>
